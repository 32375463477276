import React, {useState,useEffect} from "react";
import { Table, Tag, Space } from 'antd';
import axios from "axios";

const Bankers =()=>{

  const [users,setUsers] = useState([]);

useEffect(()=>{
  
  const token = localStorage.getItem('AccessToken')
  const headers ={
    'authorization': 'Bearer' + ' ' + token
  }

  axios.get('https://efb.ae/bankerRegister', {headers})
  .then(res=> {
      setUsers(res.data.users)
  }); 
},[])

      const columns = [
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone Number',
          dataIndex: 'phone',
          key: 'phone',
        },
      ];
      return(
        <Table columns={columns} dataSource={users} />

      )
      }
export default Bankers;