import React, {useState,useEffect} from "react";
import { Table, Space } from 'antd';
import axios from "axios";
import MessageDrawer from './messageDrawer'
import { Router } from "react-router";
import { Link, useHistory, withRouter } from "react-router-dom";

const AppointmentsList =()=>{

const [users,setUsers] = useState([]);

const attendby = () =>{

  

  
}


useEffect(()=>{

  axios.get('https://efb.ae/appointments/all')
  .then(res=> {
      console.log(res.data)
      setUsers(res.data.appointments)
  }); 
},[])

      const columns = [
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone Number',
          dataIndex: 'phone',
          key: 'phone',
        },

        {
            title: 'Service',
            dataIndex: 'services',
            key: 'services',
          },
          {
            title: 'Appointment Date',
            dataIndex: 'date',
            key: 'date',
          },

        {
          title: 'Messages',
          key: 'action',
          render: (text, record) => (

          <Link to={{
            pathname:"/messages",
            state:{
              email: record.email,
              Name: record.Name
            }  
          }}>    
            <Space size="middle" >
              {console.log(record.Name)}
              Messages
            </Space>
          </Link>  
          ),
        },

        {
          title: 'Attended By',
          key: 'action',
          render: (text, record) => (  
            <Space size="middle" onClick={attendby}>
              Attend
            </Space> 
          ),
        },
      ];
      return(
        <Table columns={columns} dataSource={users} />
      )
      }
export default AppointmentsList;