import React, { useState } from 'react';
import { Drawer, Button, Form, Select, Input, Menu } from 'antd';
import './employee.css'
import axios from 'axios';
import close from '../img/close.png'
import warning from '../img/alert.png'


const EmployeeLogin = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [error, setError]= useState(false);
  const [success, setSuccess] = useState(false)

  const showDrawer = () => {
    setVisible(true);

  };

  const onClose = () => {
    setVisible(false);
    setError(false)
    form.resetFields();
  };

  const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};




  const onFinish = (e) => {

    axios.post('https://efb.ae/employees/add', e )
     .then(res=> {
      form.resetFields();
      console.log(res.data);
      setSuccess(true)
      })


     .catch(err=>{
       console.log(err);
       setError(true);
       form.resetFields();

     })

  };

  return (
    <>
      <Button className="add-employee" onClick={showDrawer}>Add Employee</Button>
      
      <Drawer
      title="Add Employee"
      placement="right"
      onClose={onClose}
        visible={visible}
      >
        
       <section class="form-section"style={{height: "100%"}}>
              <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{
                residence: ['zhejiang', 'hangzhou', 'xihu'],
                prefix: '86',
              }}
              scrollToFirstError
            >

               <Form.Item name={['Name']} label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[{ required: true, message: 'Please input your phone number!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Add Employee
                </Button>
              </Form.Item>
            </Form>
                  
            {error ? <div class="alert-message alert-box">
            <img class="alert-sign" src={warning} alt="Alert" ></img> 
            <p class="alert-text">Email or Phone Number exists</p>
            </div>: null} 

            {success ? <div class="alert-message alert-box-pass">
            <p class="alert-pass-text">Employee Created</p>

            </div>: null}                                     
      </section>
      </Drawer>
    </>
  );
};

export default EmployeeLogin;