
import { Card, Col, Row } from 'antd';
import React from 'react'
import { useState } from 'react'
import './dashboard.css'
import axios from 'axios'

const DashboardCount= ()=>{

  const [customers,setCustomers] = useState();
  const [bankers,setBankers] = useState([]);
  const [appointments,setAppointments] = useState([]);

  const token = localStorage.getItem('AccessToken')
  const headers ={
    'authorization': 'Bearer' + ' ' + token
  }

  axios.get('https://efb.ae/register', {headers})
  .then(res=> {
    setCustomers(res.data.length)
  });

  axios.get('https://efb.ae/bankerRegister' , headers)
  .then(res=> {
    setBankers(res.data.users.length)
  });

  axios.get('https://efb.ae/appointments/all', headers)
  .then(res=> {
      console.log(res.data)
      setAppointments(res.data.appointments.length)
  });



return(
<>
<div className="site-card-wrapper">
    <Row gutter={16}>
      <Col span={8}>
        <Card title="User Registered" bordered={false}>
          {customers}
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Bankers Registered" bordered={false}>
          {bankers}
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Appointment Received" bordered={false}>
          {appointments}
        </Card>
      </Col>
    </Row>
  </div>
</>    
)}

export default DashboardCount;