import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router"
import axios from "axios";
import { Table } from 'antd';
import { Button, Form, Input, message} from 'antd';
import '../components/message.css'
import InstantMessage from './instantMessage'
import FileUpload from './fileUpload';


const Message = (props) =>{

  const { TextArea } = Input;

    const [form] = Form.useForm();
    const [mes ,setMes] = useState([]);
    const [files ,setFiles] = useState([]);

    const User_Email = (props.location.state.email);
    const cus_email = (props.location.state.email);
    const User_Name = (props.location.state.Name);
    const Emp_name = localStorage.getItem("Emp_Name")
    const Cus_email = localStorage.setItem("Cus_Email", User_Email)
    const Cus_Name = localStorage.setItem("Cus_Name", User_Name)


    console.log()

    console.log(User_Email, User_Name, Emp_name)

    useEffect(()=>{
        console.log(" Inside ",User_Email)
        axios.post('https://efb.ae/message/get-message', {User_Email})
        .then(res=> {
            console.log(res.data)
            setMes(res.data.msg)
        }); 

        axios.post('http://localhost:3002/fileupload/filelist', {cus_email})
    .then(res=> {
        console.log(res.data)
        setFiles(res.data.files)
    });        
      },[])


      const onFinish =(e) =>{

        const Query = e.Query
        const SenderName = Emp_name
        const Cus_Name = User_Name

        console.log(e)

        axios.post('https://efb.ae/message/send-message', {User_Email, SenderName, Query, Cus_Name})
        
        .then(res=> {
         console.log(res.data);
         form.resetFields();
         })
      }

      const columns = [
        {
          title: 'Sender Name',
          dataIndex: 'SenderName',
          key: 'SenderName',
        },
        {
          title: 'Message',
          dataIndex: 'Query',
          key: 'Query',
        },
        
        {
            title: 'Customer Name',
            dataIndex: 'Cus_Name',
            key: 'Cus_Name',
          },
      ];

      const columnsFiles = [
        {
          title: 'Sender Name',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'File',
          dataIndex: 'filename',
          key: 'filename',
        },
        
        {
            title: 'Customer Name',
            dataIndex: 'cus_name',
            key: 'cus_name',
          },
      ];

    return(
        <>
        <div class="msg-header">
            <h1 className="comman-class">Messages History</h1>
        </div>
        <div className="comman-class">
            <Table columns={columns} dataSource={mes} />
        </div>
      
        <div class="msg-header">
            <h1 className="comman-class">Files Uploaded</h1>
        </div>
        <div className="comman-class">
            <Table columns={columnsFiles} dataSource={files} />
        </div>  

        <div className="comman-class form-class" style={{width:"100%"}}>

          <div class="msg-header">
            <div class="msg-body">
              <h1>Reply</h1>
              <InstantMessage/>
            </div>
            <div class="msg-body">
              <h1>File Upload</h1>
              <FileUpload/>
            </div>
          </div>
        </div>
        </>
    )
}

export default withRouter(Message);
