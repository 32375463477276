import { Upload, message, Button, Form, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios";

const FileUpload = () => {

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'File Uploaded',
      description:
        'File Uploaded Successfully. Please refresh to upload new file.',
    });
  };

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      };

      const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };

      const cus_name = localStorage.getItem('Cus_Name')
      const cus_email = localStorage.getItem('Cus_Email') 
      const email = localStorage.getItem('email')

 const onFinish= (file) => {
    console.log(file)
    axios.post('http://localhost:3002/fileupload/upload', {email,file,cus_name,cus_email})
    
    .then(res=> {
     console.log(res.data);
     openNotificationWithIcon('success')
     })
  }
return (
    <Form
    name="validate_other"
    {...formItemLayout}
    onFinish={onFinish}
  >
    <Form.Item
    name="upload"
    label="Upload"
    valuePropName="fileList"
    getValueFromEvent={normFile}
    
  >
    <Upload name="logo" action="http://localhost:3002/fileupload/uploaddemoFile" listType="picture">
      <Button icon={<UploadOutlined />}>Click to upload</Button>
    </Upload>
  </Form.Item>
  <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
  </Form>


)
}

export default FileUpload;