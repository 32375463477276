import React from 'react'
import { Comment, Avatar, Form, Button, List, Input, notification} from 'antd';
import moment from 'moment';
import axios from "axios";

const { TextArea } = Input;


const Emp_name= localStorage.getItem("Emp_Name")


const openNotificationWithIcon = type => {
    notification[type]({
      message: 'Message Sent',
      description:
        'Message sent successfully. To get the reply to your query please refresh.',
    });
  };

  

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'Your replies' : 'Your reply'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} placeholder="Enter Your Message" />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Send Message
      </Button>
    </Form.Item>
  </>
);

class InstantMessage extends React.Component {
  state = {
    comments: [],
    submitting: false,
    value: '',
  };

  handleSubmit = () => {
    if (!this.state.value) {
      return;
    }

    this.setState({
      submitting: true,
    });

    setTimeout(() => {
      this.setState({
        submitting: false,
        value: '',
        comments: [
          ...this.state.comments,
          {
            author: Emp_name,
            avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            content: <p>{this.state.value}</p>,
            datetime: moment().fromNow(),
          },
        ],
      });
    }, 1000); 

    const SenderName = Emp_name
    const Query = this.state.value
    const Cus_Name = localStorage.getItem('Cus_Name')
    const User_Email= localStorage.getItem('Cus_Email')

    console.log(User_Email, SenderName, Query, Cus_Name)

    axios.post('https://efb.ae/message/send-message', {User_Email, SenderName, Query, Cus_Name})
    
    .then(res=> {
     console.log(res.data);
     openNotificationWithIcon('success')
     })
  };

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    const { comments, submitting, value } = this.state;

    return (
      <>
        {comments.length > 0 && <CommentList comments={comments} />}
        <Comment
          avatar={
            <Avatar
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              alt="Han Solo"
            />
          }
          content={
            <Editor
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              submitting={submitting}
              value={value}
            />
          }
        />
      </>
    );
  }
}

export default InstantMessage;