import React from 'react'
import './App.css'
import { BrowserRouter as Router , Route } from 'react-router-dom'
import 'antd/dist/antd.css'
import AdminDashboard from '../src/components/dashboard'
import LoginAdmin from '../src/components/adminLogin'
import Message from '../src/components/message'
function App() {
  return (
  

  <Router>

    <Route path="/messages" component={Message}></Route>
    <Route path="/admin" component={AdminDashboard}></Route>

    <Route exact path="/" component={LoginAdmin}></Route>
   
  </Router>
    )
  
  };


export default App;
