import React, {useState,useEffect} from "react";
import { Table, Tag, Space, Button } from 'antd';
import EmployeeLogin  from './employeeLogin'
import axios from "axios";
import './employee.css'

const  Employee =()=>{

  const [users,setUsers] = useState([]);

useEffect(()=>{

  axios.get('https://efb.ae/employees/all')
  .then(res=> {
    console.log(res.data)
      setUsers(res.data)
  }); 
},[])

      const columns = [
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone Number',
          dataIndex: 'phone',
          key: 'phone',
        },
      ];
      return(
        <>  
        <div className="employee-wrap">
            <EmployeeLogin/>
        </div>
        <Table columns={columns} dataSource={users} />
        </>
      )
      }
export default Employee;