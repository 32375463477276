import React from 'react'
import { Form, Input, Button,  } from 'antd';
import logo from '../img/main_logo.png'
import './adminLogin.css'
import axios from 'axios';
import { useState } from 'react';

const LoginAdmin = (props) =>{

    const [form] = Form.useForm();
    const [error, setError]= useState(false);

    
    const onFinish = (e) => {
        
        axios.post('https://efb.ae/employees/login',e )
        .then(res=> {
            
            form.resetFields();
            console.log(res.data)
            localStorage.setItem('Emp_Name', res.data.name)
            localStorage.setItem('Role', res.data.role)
            localStorage.setItem('email', res.data.employee_email)
            localStorage.setItem('AccessToken', res.data.accessToken)
            props.history.push("/admin")
        })



           .catch(err=>{
             console.log(err);
             setError(true);
             form.resetFields();
      
           })
      };
    
      const onFinishFailed = () => {
        console.log('Failed:');
      };


    return(

        <>
        <section className="admin-login-wrapper">
            <img className="admin-login-img" src={logo} alt="EFB"></img>
        </section>
        <section class="form-input-control">
                        <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >
                        <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your Email!' }]}
                        >
                        <Input />
                        </Form.Item>
                
                        <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                        <Input.Password />
                        </Form.Item>
                
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        </Form.Item>
                    </Form>
                    {error ? 
            <p class="alert-text">Invalid Credentials</p>
            : null}
        </section>
        </>
    )
}

export default LoginAdmin;