import { Layout, Menu} from 'antd';
import { UserOutlined,  } from '@ant-design/icons';
import './dashboard.css'
import DashboardCount from './home.dashboard'
import Bankers from './bankerUsers';
import { BrowserRouter as Router , Route, Link, Switch } from 'react-router-dom'
import AppointmentsList from './appointmentAdmin'
import CustomerList from './customer'
import LoginAdmin from './adminLogin'
import Employee from './employee'
import { data } from 'jquery';
import { useState } from 'react';

const AdminDashboard =() => {
const { Header, Content, Footer, Sider } = Layout;



const userRole = localStorage.getItem('Role')
const match = "Employee"

const onClickLogout =() =>{
  localStorage.removeItem('Role')
  localStorage.removeItem('AccessToken')
  localStorage.removeItem('email')
  localStorage.removeItem('Cus_Name')
  localStorage.removeItem('Cus_Email')
  localStorage.removeItem('Emp_Name')
}


return (
    <section style={{height: "100%"}}>
    <Layout>    
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="logo" >
        <Link to="/admin/"class="efb_ptag"  >EFB
      </Link>
      </div>

      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key="1" icon={<UserOutlined />}>
        <Link to="/admin/">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
        <Link to="/admin/customers">Customers</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<UserOutlined />}>
        <Link to="/admin/bankers">Bankers</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<UserOutlined />}>
        <Link to="/admin/appointments">Appointments</Link>
        </Menu.Item>
        { userRole !== match? 
        <Menu.Item key="5" icon={<UserOutlined />}>
        <Link to="/admin/employee">Employee</Link>
        </Menu.Item> : null}
        <Menu.Item key="6" icon={<UserOutlined />}>
        <Link to="/" onClick={onClickLogout}>Logout</Link>
        </Menu.Item>
          
      </Menu>
    </Sider>
    
    <Layout>
      <Header className="site-layout-sub-header-background" style={{ padding: 0 }} />
      <Content style={{ margin: '24px 16px 0' }}>
        <Switch>
        <Route path="/login" exact component={LoginAdmin}></Route>
        <Route path="/admin/employee" exact component={Employee}></Route>
        <Route path="/admin/appointments" exact component={AppointmentsList}></Route>
        <Route path="/admin/bankers" exact component={Bankers}></Route>
        <Route path="/admin/customers" component={CustomerList}></Route>
        <Route path="/admin/" component={DashboardCount}></Route>
        </Switch>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Created by EFB</Footer>
    </Layout>
  </Layout>
 </section> 
)}

export default AdminDashboard;



